import { useEffect, useState } from "react";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { sendExperimentIsInitialized } from "./helpers/analytics";
import { getExperimentVariant } from "./helpers/getExperimentVariant";
import { DEFAULT_EXPERIMENT_INDEX } from "./helpers/getRandomFromPercentages";

export const useExperiment = (id: string) => {
  const { chain: currentChain, experiments } = useAppConfig();
  const [variant, setVariant] = useState(DEFAULT_EXPERIMENT_INDEX);

  useEffect(() => {
    const experiment = experiments[id];

    if (experiment) {
      const variant = getExperimentVariant(experiment, currentChain, id);
      sendExperimentIsInitialized(id, variant);
      setVariant(variant);
    }
  }, [currentChain, experiments, id]);

  return variant;
};
