import { NovaPoshtaType } from 'src/modules/AddressManagement/components/NovaPoshtaTab/useNovaPoshtaTabProps';
import { LocalizeFunc } from "src/services/localization/types";
import { DeliveryType } from '../../redux/apiTypes';
import cutStringWithEllipsis from '../js-helpers/cutStringWithEllipsis';

interface DeliveryDataPlan {
  type: Extract<DeliveryType, 'plan'>;
  city: string;
  street: string;
  building: string;
  room?: string;
}

interface DeliveryDataPickup {
  type: Extract<DeliveryType, 'pickup'>;
  city: string;
  street: string;
  building: string;
  pickupZone?: string;
}

interface DeliveryDataNovaPoshtaWithFresh {
  type: Extract<DeliveryType, 'nova_poshta' | 'nova_poshta_fresh'>;
  localityLabel?: string;
  departmentNumber: string;
  novaPoshtaType: NovaPoshtaType;
}

interface DeliveryDataNovaPoshtaAddress {
  type: Extract<DeliveryType, 'nova_poshta_address'>;
  localityLabel?: string;
  street: string;
  building: string;
}

export type DeliveryDataForText =
  | DeliveryDataPlan
  | DeliveryDataPickup
  | DeliveryDataNovaPoshtaWithFresh
  | DeliveryDataNovaPoshtaAddress;

function isPlan(
  deliveryData: DeliveryDataForText,
): deliveryData is DeliveryDataPlan {
  return deliveryData.type === 'plan';
}

function isPickup(
  deliveryData: DeliveryDataForText,
): deliveryData is DeliveryDataPlan {
  return deliveryData.type === 'pickup';
}

function isNovaPoshtaWithFresh(
  deliveryData: DeliveryDataForText,
): deliveryData is DeliveryDataNovaPoshtaWithFresh {
  return (
    deliveryData.type === 'nova_poshta' ||
    deliveryData.type === 'nova_poshta_fresh'
  );
}

function isNovaPoshtaAddress(
  deliveryData: DeliveryDataForText,
): deliveryData is DeliveryDataNovaPoshtaAddress {
  return deliveryData.type === 'nova_poshta_address';
}

const MAX_LENGTH_OF_PART_OF_ADDRESS = 26;

const getDeliveryText = (
  deliveryData: DeliveryDataForText,
  localize: LocalizeFunc,
): string | null => {
  if (isPlan(deliveryData)) {
    const { city, street, building, room } = deliveryData;

    const localizedType = localize(`delivery.type.delivery`);
    const preparedRoom = room ? `, ${room}` : '';

    return `${localizedType}: ${city}, ${street}, ${building}${preparedRoom}`;
  }

  if (isPickup(deliveryData)) {
    const { city, street, building, pickupZone } = deliveryData;
    const localizedType = localize(`delivery.type.pickup`);
    const addressText = `${city}, ${street}, ${building}`;
    const zoneTranslationId = pickupZone === 'parking'
      ? 'pick_up_from__parking'
      : 'pick_up_from__store';
    const zoneText = localize(zoneTranslationId).toLowerCase();

    return `${localizedType}: ${addressText} (${zoneText})`;
  }

  if (isNovaPoshtaWithFresh(deliveryData)) {
    const { localityLabel, departmentNumber, novaPoshtaType } = deliveryData;

    if (!localityLabel) {
      return null;
    }

    const localizedType = localize(`np.name`);
    const preparedLocality = cutStringWithEllipsis(localityLabel, MAX_LENGTH_OF_PART_OF_ADDRESS);
    const npTypeText = novaPoshtaType === 'postomat' ? 'np_postomat' : 'np.department.short';

    const departmentLabel = `${localize(
      npTypeText,
    )} №${departmentNumber}`;

    return `${localizedType}: ${preparedLocality}, ${departmentLabel}`;
  }

  if (isNovaPoshtaAddress(deliveryData)) {
    const { localityLabel, street, building } = deliveryData;

    if (!localityLabel) {
      return null;
    }

    const localizedType = localize(`np.name`);
    const preparedLocality = cutStringWithEllipsis(localityLabel, MAX_LENGTH_OF_PART_OF_ADDRESS);
    const preparedStreet = cutStringWithEllipsis(street, MAX_LENGTH_OF_PART_OF_ADDRESS);

    return `${localizedType}: ${preparedLocality}, ${preparedStreet}, ${building}`;
  }

  return null;
};

export default getDeliveryText;
