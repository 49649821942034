import { Address } from '../../redux/addressManagement/types';
import { DeliveryDataForText } from './getDeliveryText';

export const getDeliveryDataFromAddress = (
  address: Address,
): DeliveryDataForText => {
  const street: string = typeof address.street === 'object' ? address.street.name : address.street;

  return {
    type: address.delivery,
    city: address.city,
    street: street,
    building: address.building,
    localityLabel: address.locality?.label,
    departmentNumber: address.department?.number.toString(),
    pickupZone: address.pickupZone,
    novaPoshtaType: address.department?.type,
  };
};
