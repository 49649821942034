import { useEffect, useRef } from 'react';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { selectStoreId } from '../../../redux/cart/general/cartSelectors';
import selectAddress from '../../../redux/cart/general/selectAddress';
import { useAppSelector } from '../../../redux/hooks';
import selectScheduleDeliveryProps from '../../../swr/useScheduleDelivery/selectScheduleDeliveryProps';
import useScheduleDelivery from '../../../swr/useScheduleDelivery/useScheduleDelivery';
import { DeliveryContractSetData, sendDeliveryContractSet } from '../../../utils/marketing/enhancedEcommerce';

interface ScheduleDeliveryAnalyticsWatcherProps {
  event: DeliveryContractSetData['event'],
}

const ScheduleDeliveryAnalyticsWatcher = (props: ScheduleDeliveryAnalyticsWatcherProps) => {
  const { event } = props;

  const scheduleDeliveryProps = useAppSelector(selectScheduleDeliveryProps);
  const storeId = useAppSelector(selectStoreId);
  const deliveryType = useAppSelector(s => selectAddress(s)?.delivery);
  const { scheduleDeliveryList } = useScheduleDelivery(scheduleDeliveryProps);
  const deliveryZone = scheduleDeliveryList[0]?.delivery_zone;
  const deliveryContractSetDataRef = useRef<DeliveryContractSetData>();
  const { chain } = useAppConfig();

  deliveryContractSetDataRef.current = {
    event,
    storeId,
    chain,
    deliveryType,
    scheduleDeliveryList,
  };

  useEffect(() => {
    if(deliveryZone) {
      sendDeliveryContractSet(deliveryContractSetDataRef.current);
    }
  }, [deliveryZone]);

  return null;
};

export default ScheduleDeliveryAnalyticsWatcher;
